import React, { useState } from "react";
import { proxy } from "../../env/proxy";

const OtpInput = (props: any) => {
  const requestNewOtp = async () => {
    setTimeout(() => setRequested(false), 120000);
    try {
      const email = localStorage.getItem("email");
      if (!email) {
        window.location.reload();
      }
      const url = `${proxy.url}/login/request-login-otp`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });
      const resData = await response.json();
    } catch (error) {
      console.log(error);
      props.setLoginError("Error requesting new OTP");
    }
  };
  const [requested, setRequested] = useState<boolean>(false);

  return (
    <div className="login-mini-form">
      <div className="login-input-container">
        <input
          type="number"
          placeholder="Login Code"
          onChange={props.onOtpChange}
        />
      </div>

      <button
        className="login-btn"
        disabled={!props.otpBtn}
        onClick={props.submitOtp}
      >
        Log In
      </button>

      {!requested ? (
        <div className="login-otp-resend" onClick={requestNewOtp}>
          Resend Code
        </div>
      ) : (
        <div className="login-otp-resend-requested">
          New code has been sent to your email
        </div>
      )}
    </div>
  );
};

export default OtpInput;
