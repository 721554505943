import React from "react";
import { NavLink } from "react-router-dom";

const NavMenu = (props: any) => {
  const activeStyle = {
    border: "2px solid #3d5468",
    color: "#0f293e",
    backgroundColor: "#f6f6f6",
  };

  const localUser = localStorage.getItem("UserData");
  const user = localUser ? JSON.parse(localUser) : null;
  const isAdmin = user ? user.role === "admin" : false;

  return (
    <div
      className="navbar-menu-container"
      style={{ display: props.show ? "block" : "none" }}
    >
      <div className="navbar-menu">
        <span className="nav-link-container-menu">
          <NavLink
            to="create-store"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            onClick={() => props.setShow(false)}
          >
            Dodaj Trgovino
          </NavLink>
        </span>
        <span className="nav-link-container-menu">
          <NavLink
            to="create-currency"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            onClick={() => props.setShow(false)}
          >
            dodaj valuto
          </NavLink>
        </span>
        <span className="nav-link-container-menu">
          <NavLink
            to="update-currency"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            onClick={() => props.setShow(false)}
          >
            posodobi valuto{" "}
          </NavLink>
        </span>
        {isAdmin ? (
          <span className="nav-link-container-menu">
            <NavLink
              to="products"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              onClick={() => props.setShow(false)}
            >
              Uredi Izdelke
            </NavLink>
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default NavMenu;
