import React, { useEffect, useState } from "react";
import CostRow from "./costRow";
import {
  CostTableRowData,
  CostsResult,
  Order,
  OrderItem,
} from "../../pages/Costs";
import TotalsTable from "./TotalsTable";
import { ProductInterface } from "../../pages/Products";

const TotalsSection = (props: {
  stores: CostsResult[];
  totalCost: number;
  products: ProductInterface[];
}) => {
  const { stores, totalCost } = props;
  const [allItemsCombined, setAllItemsCombined] = useState<CostTableRowData[]>(
    []
  );
  const [totals, setTotals] = useState<CostTableRowData>({
    sku: "Total",
    itemsSold: 0,
    inOrders: 0,
    totalRevenue: 0,
    costPerItem: 0,
    totalCost: 0,
  });
  const combineItemsFromAllStores = (
    stores: CostsResult[]
  ): CostTableRowData[] => {
    //GET ALL SKUS
    const result: CostTableRowData[] = [];

    //Convert revenue to EUR
    const allOrdersConverted: Order[] = [];
    stores.forEach((store) => {
      store.orders.forEach((order) => {
        order.items.forEach((item) => {
          const convertedTotal = item.total;
          item.total = convertedTotal;
          item.price = item.price;
        });
        allOrdersConverted.push(order);
      });
    });

    //Extract all items from all orders
    //Extract Unique SKUs
    const uniqueSkus: string[] = [];
    const itemsFromAllOrders: OrderItem[] = [];
    allOrdersConverted.forEach((order) => {
      order.items.forEach((item) => {
        if (!uniqueSkus.includes(item.sku)) {
          uniqueSkus.push(item.sku);
        }
        itemsFromAllOrders.push(item);
      });
    });
    //Match items to unique SKUs and create new object for each item
    uniqueSkus.forEach((sku) => {
      let itemsSold = 0;
      let inOrders = 0;
      let totalRevenue = 0;
      let totalCost = 0;
      const costPerItem = matchCostToItem(sku, props.products);
      //Match items by sku
      itemsFromAllOrders.forEach((item) => {
        if (item.sku === sku) {
          itemsSold += item.quantity;
          inOrders++;
          totalRevenue += item.price * item.quantity;
          totalCost += costPerItem * item.quantity;
        }
      });
      //Push new object to result array

      result.push({
        sku,
        itemsSold,
        inOrders,
        totalRevenue: parseFloat(totalRevenue.toFixed(2)),
        costPerItem,
        totalCost: parseFloat(totalCost.toFixed(2)),
      });
    });
    //GET TOTAL AMOUNT OF ITEMS IN ALL ORDERS (orderCount*quantity per order)
    //GET NUMBER OF ORDERS IN WHICH ITEM WAS BOUGHT
    //SET COLUMN FOR COST OF AN ITEM -> input
    //SET ON COST INPUT CHANGE CALCULATE TOTAL COST OF ITEMS
    return result;
  };

  const matchCostToItem = (
    sku: string,
    products: ProductInterface[]
  ): number => {
    let cost = 0;
    products.forEach((product) => {
      if (product.sku === sku) {
        cost = product.cost;
      }
    });
    return cost;
  };

  const processTotals = (data: CostTableRowData[]): CostTableRowData => {
    const result = {
      sku: "Total",
      itemsSold: 0,
      inOrders: 0,
      totalRevenue: 0,
      costPerItem: 0,
      totalCost: 0,
    };

    data.forEach((item) => {
      if (!item.totalCost) item.totalCost = 0;
      result.itemsSold += item.itemsSold;
      result.inOrders += item.inOrders;
      result.totalRevenue += item.totalRevenue;
      result.totalCost += item.totalCost;
    });

    result.costPerItem = 0;
    result.totalRevenue = parseFloat(result.totalRevenue.toFixed(2));
    result.costPerItem = parseFloat(
      (result.totalCost / result.itemsSold).toFixed(2)
    );
    return result;
  };

  useEffect(() => {
    setAllItemsCombined(combineItemsFromAllStores(stores));
    setTotals(processTotals(combineItemsFromAllStores(stores)));
  }, [stores]);
  return (
    <div
      className="table-container input-super-conatiner"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2>Totals prikaz</h2>
      {stores.length > 0 && (
        <TotalsTable
          allItemsCombined={allItemsCombined}
          totalCost={totalCost}
          totals={totals}
        />
      )}
    </div>
  );
};

export default TotalsSection;
