import React from "react";

const EmailInput = (props: any) => {
  return (
    <div className="login-mini-form">
      <div className="login-input-container">
        <input
          type="email"
          placeholder="Email"
          onChange={props.onEmailChange}
        />
      </div>

      <button
        className="login-btn"
        disabled={!props.btn}
        onClick={props.requestOtp}
      >
        Log In
      </button>
    </div>
  );
};

export default EmailInput;
