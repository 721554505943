import React from "react";
import { ProductInterface } from "../../pages/Products";

const ProductSelect = (props: {
  products: ProductInterface[];
  setSku: any;
}) => {
  const onChangeSku = (e: any) => {
    props.setSku(e.target.value);
  };
  return (
    <select onChange={onChangeSku}>
      <option value="" disabled={true} selected={true}>
        Izberi izdelek
      </option>
      {props.products.map((product) => (
        <option key={product.sku} value={product.sku}>
          {product.sku}
        </option>
      ))}
    </select>
  );
};

export default ProductSelect;
