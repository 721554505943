import { queryDate } from "../../../types/Store";
import Day from "./Day";
import Month from "./Month";
import Year from "./Year";

const Select = (props: {
  state: queryDate;
  stateSet: any;
  initial: queryDate;
}) => {
  return (
    <div className="select-super-container">
      <Day
        state={props.state}
        stateSet={props.stateSet}
        initial={props.initial.day}
      />
      <Month
        state={props.state}
        stateSet={props.stateSet}
        initial={props.initial.month}
      />
      <Year
        state={props.state}
        stateSet={props.stateSet}
        initial={props.initial.month}
      />
    </div>
  );
};

export default Select;
