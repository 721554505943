import React from "react";

const RadioElement = (props: {
  name: string;
  id: string;
  setValue: any;
  label: string;
  checked: boolean;
  value: string;
}) => {
  const handleChange = (e: any) => {
    props.setValue(e.target.value);
  };
  return (
    <div className="radio-container">
      <input
        type="radio"
        name={props.name}
        id={props.id}
        checked={props.checked}
        value={props.value}
        onChange={handleChange}
        style={{ cursor: "pointer" }}
      />
      <label htmlFor={props.id} style={{ cursor: "pointer" }}>
        {props.label}
      </label>
    </div>
  );
};

export default RadioElement;
