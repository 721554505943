import { queryDate } from "../../types/Store";
import PeriodToggle from "./PeriodToggle";
import Select from "./select/SelectBox";

const SelectContainer = (props: {
  start: queryDate;
  end: queryDate;
  periodSet: any;
  period: boolean;
  setStart: any;
  setEnd: any;
}) => {
  return (
    <div className="select-box-container">
      <div className="both-selects-container">
        <PeriodToggle periodSet={props.periodSet} period={props.period} />
        <div className="select-and-label">
          <span className="select-label">Start: </span>
          <Select
            state={props.start}
            stateSet={props.setStart}
            initial={props.start}
          />
        </div>
        {
          <div
            className="select-and-label"
            style={{ display: !props.period ? "none" : "flex" }}
          >
            <span className="select-label">End: </span>
            <Select
              state={props.end}
              stateSet={props.setEnd}
              initial={props.end}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default SelectContainer;
