import { Chart as ChartJS, registerables } from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { AnalyticsChartDataProcessed } from "../../types/Totals";

ChartJS.register(...registerables);
const ChartContainer = (props: {
  data: AnalyticsChartDataProcessed[];
  dataPrev: AnalyticsChartDataProcessed[];
}) => {
  const getChartLabels = (data: AnalyticsChartDataProcessed[]): string[] => {
    const result: string[] = [];
    data.map((i) => {
      result.push(i.time);
    });
    return result;
  };
  const getChartSpend = (data: AnalyticsChartDataProcessed[]): number[] => {
    const result: number[] = [];
    data.map((i) => {
      result.push(i.spend);
    });
    return result;
  };
  const getChartRoas = (data: AnalyticsChartDataProcessed[]): number[] => {
    const result: number[] = [];
    data.map((i) => {
      result.push(i.roas);
    });
    return result;
  };
  const getChartRevenue = (data: AnalyticsChartDataProcessed[]): number[] => {
    const result: number[] = [];
    data.map((i) => {
      result.push(i.revenue);
    });
    return result;
  };

  const prevChartData = [...props.dataPrev];
  if (props.data.length > props.dataPrev.length) {
    const diff = props.data.length - props.dataPrev.length;
    for (let i = 0; i < diff; i++) {
      prevChartData.unshift({ time: "", spend: 0, revenue: 0, roas: 0 });
    }
  }
  /* 
  //SORT ARRAY BY HOUR DESC
  const sortedData = props.data.sort((a, b) => {
    const aHour = new Date(a.time).getHours();
    const bHour = new Date(b.time).getHours();
    return aHour - bHour;
  });
  const sortedPrevData = prevChartData.sort((a, b) => {
    const aHour = new Date(a.time).getHours();
    const bHour = new Date(b.time).getHours();
    return aHour - bHour;
  });

  const extractedPrevData = [];
  for (let i = 0; i < sortedData.length; i++) {
    extractedPrevData.push(sortedPrevData[i]);
  }

  sortedData.sort((a, b) => {
    const aHour = new Date(a.time).getHours();
    const bHour = new Date(b.time).getHours();
    return bHour - aHour;
  });

  extractedPrevData.sort((a, b) => {
    const aHour = new Date(a.time).getHours();
    const bHour = new Date(b.time).getHours();
    return bHour - aHour;
  }); */

  //EXTRACT DATA WITH SAME HOUR FROM PREVDATA
  const doesHourMatch = (time: string, timePrev: string): boolean => {
    /* const hour = new Date(time).getHours();
    const hourPrev = new Date(timePrev).getHours();
     */
    return time === timePrev;
  };

  //EXTRACT DATA WITH SAME HOUR FROM PREVDATA
  const extractedPrevData: AnalyticsChartDataProcessed[] = [];
  props.data.forEach((current) => {
    props.dataPrev.forEach((prev) => {
      if (
        doesHourMatch(current.time, prev.time) &&
        !extractedPrevData.includes(prev)
      ) {
        extractedPrevData.push(prev);
      }
    });
  });

  const sortedData = [...props.data];
  //SORT DATA BY HOUR DESC
  sortedData.sort((a, b) => {
    const aHour = new Date(a.time).getHours();
    const bHour = new Date(b.time).getHours();
    return bHour - aHour;
  });

  extractedPrevData.sort((a, b) => {
    const aHour = new Date(a.time).getHours();
    const bHour = new Date(b.time).getHours();
    return bHour - aHour;
  });

  //ADD MISSING DATA TO EXTRACTEDPREVDATA
  const diff = sortedData.length - extractedPrevData.length;
  if (diff > 0) {
    for (let i = 0; i < diff; i++) {
      extractedPrevData.unshift({ time: "", spend: 0, revenue: 0, roas: 0 });
    }
  }
  //SORT DATA BY HOUR ASC
  sortedData.sort((a, b) => {
    const aHour = new Date(a.time).getHours();
    const bHour = new Date(b.time).getHours();
    return aHour - bHour;
  });

  extractedPrevData.sort((a, b) => {
    const aHour = new Date(a.time).getHours();
    const bHour = new Date(b.time).getHours();
    return aHour - bHour;
  });

  return (
    <div className={"totals-chart-super-container"}>
      <div className={"totals-chart-container"}>
        <h2>SPEND</h2>
        <Line
          data={{
            labels: getChartLabels(sortedData),
            datasets: [
              {
                label: "Spend",
                data: getChartSpend(sortedData),
                borderColor: "rgb(54,162,235",
                backgroundColor: "rgba(54,162,235, 0.2)",
              },
              {
                label: "Spend -1",
                data: getChartSpend(extractedPrevData),
                borderColor: "rgba(54,162,235,0.2)",
                backgroundColor: "rgba(54,162,235, 0.1)",
              },
            ],
          }}
        />
        <h2>REVENUE</h2>
        <Line
          data={{
            labels: getChartLabels(sortedData),
            datasets: [
              {
                label: "Revenue",
                data: getChartRevenue(sortedData),

                borderColor: "rgb(75,192,192)",
                backgroundColor: "rgba(75,192,192, 0.2)",
              },
              {
                label: "Revenue -1",
                data: getChartRevenue(extractedPrevData),

                borderColor: "rgba(75,192,192, 0.2)",
                backgroundColor: "rgba(75,192,192, 0.1)",
              },
            ],
          }}
        />
        <h2>ROAS</h2>
        <Line
          data={{
            labels: getChartLabels(sortedData),
            datasets: [
              {
                label: "Roas",
                data: getChartRoas(sortedData),
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "red",
              },
              {
                label: "Roas -1",
                data: getChartRoas(extractedPrevData),
                backgroundColor: "rgba(255, 99, 132, 0.1)",
                borderColor: "rgba(255, 99, 132, 0.2)",
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default ChartContainer;
