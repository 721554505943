import { CostsResult } from "../../pages/Costs";

const StoresSelect = (props: {
  stores: CostsResult[];
  setStore: any;
  initial: number | string;
}) => {
  const handleChange = (e: any) => {
    props.setStore(e.target.value);
  };
  return (
    <select
      onChange={handleChange}
      style={{ margin: "0" }}
      defaultValue={props.initial || "null"}
    >
      <option value="null" disabled>
        Izberi trgovino
      </option>
      {props.stores.map((store) => {
        return (
          <option value={store.storeId} key={store.storeId}>
            {store.storeName}
          </option>
        );
      })}
    </select>
  );
};

export default StoresSelect;
