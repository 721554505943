const ProductSectionRadio = (props: any) => {
  const handleChange = (e: any) => {
    props.setOperation(e.target.value);
  };
  const checked = props.operation === props.value;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <input
        type="radio"
        name={props.name}
        id={props.value}
        value={props.value}
        checked={checked}
        onChange={handleChange}
        disabled={props.products.length === 0}
      />
      <label htmlFor={props.value}>{props.label}</label>
    </div>
  );
};

export default ProductSectionRadio;
