import { useEffect, useState } from "react";
import { proxy } from "../env/proxy";
import { ProductInterface } from "./Products";
import TotalsSection from "../components/costs/TotalsSection";
import RadioElement from "../components/costs/RadioElement";
import StoreSection from "../components/costs/StoreSection";
import SelectContainer from "../components/inputs/SelectContainer";
import { queryDate } from "../types/Store";
import LoadButton from "../components/inputs/LoadButton";

const CostsPage = () => {
  const [stores, setStores] = useState<CostsResult[]>([]);

  const currentDate = new Date(Date.now());
  const currentStateStart: queryDate = {
    day: currentDate.getDate(),
    month: currentDate.getMonth() + 1,
    year: currentDate.getFullYear(),
  };
  const [start, setStart] = useState<queryDate>(currentStateStart);
  const [end, setEnd] = useState<queryDate>(currentStateStart);
  const [period, setPeriod] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [totalCost, setTotalCost] = useState<number>(0);
  const [products, setProducts] = useState<ProductInterface[]>([]);
  const [display, setDisplay] = useState<CostsDisplay>(CostsDisplay.TOTALS);
  const concatDate = (date: StateDate) => {
    return `${date.year}-${date.month}-${date.day}`;
  };

  useEffect(() => {
    onInit();
  }, []);
  const onInit = async () => {
    setLoading(true);
    const products = await fetchProducts();
    setProducts(products);
    const data = await fetchStoreData();
  };
  const fetchProducts = async () => {
    try {
      const url = `${proxy.url}/products`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      });

      const resData: ProductInterface[] = await response.json();

      if (resData instanceof Array) {
        return resData;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };
  const fetchStoreData = async () => {
    setLoading(true);
    try {
      const url = `${proxy.url}/costs?s=${concatDate(start)}&e=${concatDate(
        end
      )}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      });

      const resData: CostsResult[] = await response.json();

      setStores(resData);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onClickLoad = async () => {
    setLoading(true);
    const data = await fetchStoreData();
    setLoading(false);
  };
  if (loading) {
    return (
      <div className="loading-div">
        <img src="/logo512.png"></img>
        Loading
      </div>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: "100px",
      }}
    >
      <div
        className="page-level-input-container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <SelectContainer
          period={period}
          periodSet={setPeriod}
          start={start}
          end={end}
          setEnd={setEnd}
          setStart={setStart}
        />
        <div className="load-and-4days-btn-container">
          <LoadButton trigger={onClickLoad} disabled={false} />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          margin: "15px 0",
          padding: "10px 30px",
          border: "2px solid black",
        }}
      >
        <RadioElement
          name="display"
          label="Totals"
          id="totals"
          value={CostsDisplay.TOTALS}
          setValue={setDisplay}
          checked={CostsDisplay.TOTALS === display}
        />
        <RadioElement
          name="display"
          label="Po trgovinah"
          id="stores"
          value={CostsDisplay.STORES}
          setValue={setDisplay}
          checked={CostsDisplay.STORES === display}
        />
      </div>

      {display === CostsDisplay.TOTALS ? (
        <TotalsSection
          stores={stores}
          totalCost={totalCost}
          products={products}
        />
      ) : null}

      {display === CostsDisplay.STORES ? (
        <StoreSection stores={stores} products={products} />
      ) : null}
    </div>
  );
};
export default CostsPage;
interface StateDate {
  day: number;
  month: number;
  year: number;
}
export interface CostsResult {
  storeId: number;
  storeName: string;
  currency: Currency;
  orders: Order[];
  cost: number;
}
export interface Order {
  id: number;
  total: string;
  status: string;
  items: OrderItem[];
  ab_landing: string;
  old_utm: old_utm;
  campaign_id: string;
  adset_id: string;
  ad_id: string;
  items_q: number;
  items_num: number;
  campaign_name?: string;
  adset_name?: string;
  ad_name?: string;
  utm_source?: string;
}
export interface OrderItem {
  id: number;
  sku: string;
  quantity: number;
  total: number;
  price: number;
}

export interface old_utm {
  pys_landing: string;
  pys_source: string;
  pys_utm: string;
  pys_browser_time: string;
}

export interface Currency {
  readonly id: number;
  readonly name: string;
  readonly rate: number;
  readonly prevVal?: number;
}

export interface CostTableRowData {
  sku: string;
  itemsSold: number;
  inOrders: number;
  totalRevenue: number;
  costPerItem: number;
  totalCost?: number;
}

export enum CostsDisplay {
  STORES = "stores",
  TOTALS = "totals",
}

export interface StoreResults {
  storeId: number;
  storeName: string;
  items: CostTableRowData[];
  totalCost: number;
  totalRevenue: number;
}
