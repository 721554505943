import { useEffect, useState } from "react";
import ProductSelect from "../components/products/ProductSelect";
import { proxy } from "../env/proxy";
import ProductSectionRadio from "../components/products/ProductSectionRadio";

const ProductsPage = () => {
  const [products, setProducts] = useState<ProductInterface[]>([]);
  const [productForUpdate, setProductForUpdate] = useState<ProductInterface>({
    sku: "",
    cost: 0,
  });
  const [productForDelete, setProductForDelete] = useState<ProductInterface>({
    sku: "",
    cost: 0,
  });
  const [newProduct, setNewProduct] = useState<ProductInterface>({
    sku: "",
    cost: 0,
  });

  const updateSelectedProductSku = (sku: string) => {
    setProductForUpdate({ ...productForUpdate, sku: sku });
  };

  const deleteSelectedProductSku = (sku: string) => {
    setProductForDelete({ ...productForDelete, sku: sku });
  };

  const [operation, setOperation] = useState<string>("add");

  const fetchProducts = async () => {
    const result = await fetch(`${proxy.url}/products`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const data = await result.json();
    setProducts(data);
  };

  const updateProduct = async () => {
    const result = await fetch(`${proxy.url}/products`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
      body: JSON.stringify(productForUpdate),
    });
    const data = await result.json();
    if (data instanceof Array) {
      setProducts(data);
    }
  };

  const deleteProduct = async () => {
    const result = await fetch(`${proxy.url}/products`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
      body: JSON.stringify(productForDelete),
    });
    const data = await result.json();
    if (data instanceof Array) {
      setProducts(data);
    }
  };

  const addProduct = async () => {
    const result = await fetch(`${proxy.url}/products`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
      body: JSON.stringify(newProduct),
    });
    const data = await result.json();
    if (data instanceof Array) {
      setProducts(data);
    }
  };

  const onCheckRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOperation(e.target.value);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="input-super-conatiner">
      <h1>Izdelki</h1>

      <div
        style={{
          display: "flex",
          gap: "10px",
          padding: "10px 30px",
          border: "2px solid black",
        }}
      >
        <ProductSectionRadio
          name="operation"
          value="update"
          operation={operation}
          products={products}
          setOperation={setOperation}
          label="Posodobi"
        />
        <ProductSectionRadio
          name="operation"
          value="delete"
          operation={operation}
          products={products}
          setOperation={setOperation}
          label="Izbriši"
        />
        <ProductSectionRadio
          name="operation"
          value="add"
          operation={operation}
          products={products}
          setOperation={setOperation}
          label="Dodaj"
        />
        <ProductSectionRadio
          name="operation"
          value="view"
          operation={operation}
          products={products}
          setOperation={setOperation}
          label="Pregled"
        />
      </div>

      {operation === "view" ? (
        <div className="table-container">
          <h2>Seznam izdelkov</h2>
          <table>
            <thead>
              <tr>
                <th>SKU</th>
                <th>Strosek</th>
              </tr>{" "}
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.sku}>
                  <td>{product.sku}</td>
                  <td>{product.cost}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}

      {operation === "update" ? (
        <div
          id="update-container "
          className="input-container"
          style={{
            border: "1px solid black",
            padding: "10px 30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px 0",
          }}
        >
          <h2>Posodobi izdelek</h2>
          <ProductSelect
            products={products}
            setSku={updateSelectedProductSku}
          />
          <h4>Nabavna cena izdelka</h4>
          <input
            type="number"
            placeholder="Cena"
            onChange={(e) =>
              setProductForUpdate({
                ...productForUpdate,
                cost: parseFloat(e.target.value),
              })
            }
          />
          <p>Nabavna cena v EUR (1.5)</p>
          <button onClick={updateProduct}>Posodobi</button>
        </div>
      ) : null}

      {operation === "delete" ? (
        <div
          id="delete-container"
          style={{
            border: "1px solid black",
            padding: "10px 30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px 0",
          }}
          className="input-container"
        >
          <h2>Izbriši izdelek</h2>
          <h4>Izberi SKU Izdelka</h4>
          <ProductSelect
            products={products}
            setSku={deleteSelectedProductSku}
          />
          <button style={{ marginTop: "10px" }} onClick={deleteProduct}>
            Izbriši
          </button>
        </div>
      ) : null}

      {operation === "add" ? (
        <div
          id="add-product"
          className="input-container"
          style={{
            border: "1px solid black",
            padding: "10px 30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px 0",
          }}
        >
          <h2>Dodaj nov izdelek</h2>
          <h4>SKU Izdelka</h4>
          <input
            type="text"
            placeholder="SKU"
            onChange={(e) =>
              setNewProduct({ ...newProduct, sku: e.target.value })
            }
          />
          <p>Npr. BF-R</p>
          <h4>Nabavna cena izdelka</h4>
          <input
            type="number"
            placeholder="Cena"
            onChange={(e) =>
              setNewProduct({ ...newProduct, cost: parseFloat(e.target.value) })
            }
          />
          <p>Nabavna cena v EUR (1.5)</p>
          <button onClick={addProduct}>Dodaj</button>
        </div>
      ) : null}
    </div>
  );
};
export default ProductsPage;
export interface ProductInterface {
  sku: string;
  cost: number;
}
