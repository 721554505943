import { Outlet, Navigate } from "react-router-dom";

const AdminProtectedRoute = () => {
  const loggedIn = localStorage.getItem("AccessToken");
  const userJson = localStorage.getItem("UserData");
  const userData = userJson ? JSON.parse(userJson) : null;

  return loggedIn && userData.role == "admin" ? (
    <Outlet />
  ) : (
    <Navigate to={"/"} />
  );
};

export default AdminProtectedRoute;
