import React from "react";
import { NavLink } from "react-router-dom";
import NavMenu from "./navbar/NavMenu";
const Navbar = (props: { loggedIn: boolean }) => {
  const activeStyle = {
    color: "#f6f6f6",
  };
  const loggedIn = props.loggedIn;
  const [showMenu, setShowMenu] = React.useState<boolean>(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const localUser = localStorage.getItem("UserData");
  const user = localUser ? JSON.parse(localUser) : null;
  const isAdmin = user ? user.role === "admin" : false;
  const isGuest = user ? user.role === "guest" : false;
  return loggedIn ? (
    <div className="navbar">
      {isGuest ? "" : <NavMenu show={showMenu} setShow={setShowMenu} />}
      <div className="nav-menu-icon">
        <img src="/hb-menu.png" onClick={toggleMenu} />
      </div>
      <div className="nav-logo-container">2BS</div>

      <div className="nav-link-super-container">
        <span className="nav-link-container">
          <NavLink
            to="analytics"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            analytics{" "}
          </NavLink>
        </span>

        {isAdmin ? (
          <span className="nav-link-container">
            <NavLink
              to="costs"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              Stroski{" "}
            </NavLink>
          </span>
        ) : null}
      </div>

      <div className="nav-select-container"></div>
    </div>
  ) : (
    <div></div>
  );
};

export default Navbar;
