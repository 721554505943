import React from "react";
import { CostTableRowData } from "../../pages/Costs";

const CostRow = (props: { item: CostTableRowData; totalCost: number }) => {
  const item = props.item;
  const [costPerItem, setCostPerItem] = React.useState<number>(0);
  const [costPerItemPrevious, setCostPerItemPrevious] =
    React.useState<number>(0);

  return (
    <tr>
      <td>{item.sku}</td>
      <td>{item.itemsSold}</td>
      <td>{item.inOrders}</td>
      <td>{item.totalRevenue.toFixed(2)}</td>
      <td>{item.costPerItem}</td>
      <td>{item.totalCost}</td>
      <td>
        {item.totalCost
          ? (item.totalRevenue - item.totalCost).toFixed(2)
          : item.totalRevenue.toFixed(2)}
      </td>
    </tr>
  );
};

export default CostRow;
